var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "context-picker-modal" },
    [
      _c("b-modal", {
        ref: "modal-create-context",
        attrs: {
          id: "modal-create-context",
          "footer-class": "flex-nowrap",
          "no-close-on-backdrop": "",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [_vm._v(_vm._s(_vm.$t("modals.createContext.title")))])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("div", { staticClass: "m-4" }, [
                  _vm.availableActions.length === 0
                    ? _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("modals.createContext.noActions")) +
                              " "
                          ),
                          _c("br"),
                          _c(
                            "b-button",
                            {
                              staticClass:
                                "green-button-transparent border-0 mt-3",
                              on: {
                                click: function($event) {
                                  return _vm.$emit("cancel-modal")
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("buttons.close")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "b-row",
                            { staticClass: "mb-4" },
                            [
                              _c("b-col", { attrs: { cols: "4" } }, [
                                _c("label", { attrs: { for: "value-name" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("modals.createContext.operation")
                                      ) +
                                      " "
                                  )
                                ])
                              ]),
                              _c(
                                "b-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c("b-form-group", {
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function({
                                          ariaDescribedbyAction
                                        }) {
                                          return _vm._l(
                                            _vm.availableActions,
                                            function(actionObject) {
                                              return _c(
                                                "b-form-radio",
                                                {
                                                  key: actionObject.value,
                                                  staticClass: "mb-1",
                                                  attrs: {
                                                    "aria-describedby": ariaDescribedbyAction,
                                                    name: "action",
                                                    disabled:
                                                      actionObject.disabled,
                                                    value: actionObject.value
                                                  },
                                                  model: {
                                                    value: _vm.action,
                                                    callback: function($$v) {
                                                      _vm.action = $$v
                                                    },
                                                    expression: "action"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(actionObject.text) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            }
                                          )
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mb-4" },
                            [
                              _c("b-col", { attrs: { cols: "4" } }, [
                                _c("label", { attrs: { for: "value-name" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("modals.createContext.value")
                                      ) +
                                      " "
                                  )
                                ])
                              ]),
                              _c("b-col", { attrs: { cols: "8" } }, [
                                _c(
                                  "div",
                                  [
                                    _c("b-form-group", {
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function({
                                            ariaDescribedbyValue
                                          }) {
                                            return [
                                              _c(
                                                "b-form-radio",
                                                {
                                                  staticClass:
                                                    "mb-1 text-uppercase",
                                                  attrs: {
                                                    "aria-describedby": ariaDescribedbyValue,
                                                    name: "source",
                                                    value: "personalization"
                                                  },
                                                  model: {
                                                    value: _vm.source,
                                                    callback: function($$v) {
                                                      _vm.source = $$v
                                                    },
                                                    expression: "source"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "modals.createContext.source.personalization"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "b-form-radio",
                                                {
                                                  staticClass:
                                                    "mb-1 text-uppercase",
                                                  attrs: {
                                                    "aria-describedby": ariaDescribedbyValue,
                                                    name: "source",
                                                    disabled: "",
                                                    value: "localStorage"
                                                  },
                                                  model: {
                                                    value: _vm.source,
                                                    callback: function($$v) {
                                                      _vm.source = $$v
                                                    },
                                                    expression: "source"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "modals.createContext.source.localStorage"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "b-form-radio",
                                                {
                                                  staticClass: "text-uppercase",
                                                  attrs: {
                                                    "aria-describedby": ariaDescribedbyValue,
                                                    name: "source",
                                                    disabled: "",
                                                    value: "urlParameter"
                                                  },
                                                  model: {
                                                    value: _vm.source,
                                                    callback: function($$v) {
                                                      _vm.source = $$v
                                                    },
                                                    expression: "source"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "modals.createContext.source.urlParams"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    })
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("cancel-modal")
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: {
                      variant: "primary",
                      block: "",
                      disabled: _vm.disableCreateButton
                    },
                    on: { click: _vm.createEntry }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.create")) + " ")]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }