<template>
  <div class="context-picker-modal">
    <b-modal
        id="modal-create-context"
        ref="modal-create-context"
        footer-class="flex-nowrap"
        no-close-on-backdrop
        centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.createContext.title') }}</h3>
      </template>

      <template #default>
        <div class="m-4">
          <div v-if="availableActions.length === 0" class="text-center">
            {{ $t('modals.createContext.noActions') }}
            <br>
            <b-button
                class="green-button-transparent border-0 mt-3"
                @click="$emit('cancel-modal')">
              {{ $t('buttons.close') }}
            </b-button>
          </div>
          <div v-else>
            <b-row class="mb-4">
              <b-col cols="4">
                <label for="value-name">
                  {{ $t('modals.createContext.operation') }}
                </label>
              </b-col>
              <b-col cols="8">
                <b-form-group v-slot="{ ariaDescribedbyAction }">
                  <b-form-radio
                      v-for="actionObject in availableActions"
                      v-model="action"
                      class="mb-1"
                      :key="actionObject.value"
                      :aria-describedby="ariaDescribedbyAction"
                      name="action"
                      :disabled="actionObject.disabled"
                      :value="actionObject.value">{{ actionObject.text }}
                  </b-form-radio>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col cols="4">
                <label for="value-name">
                  {{ $t('modals.createContext.value') }}
                </label>
              </b-col>
              <b-col cols="8">
                <div>
                  <b-form-group v-slot="{ ariaDescribedbyValue }">
                    <b-form-radio
                        v-model="source"
                        class="mb-1 text-uppercase"
                        :aria-describedby="ariaDescribedbyValue"
                        name="source"
                        value="personalization">
                      {{ $t('modals.createContext.source.personalization') }}
                    </b-form-radio>
                    <b-form-radio
                        v-model="source"
                        class="mb-1 text-uppercase"
                        :aria-describedby="ariaDescribedbyValue"
                        name="source"
                        disabled
                        value="localStorage">
                      {{ $t('modals.createContext.source.localStorage') }}
                    </b-form-radio>
                    <b-form-radio
                        v-model="source"
                        class="text-uppercase"
                        :aria-describedby="ariaDescribedbyValue"
                        name="source"
                        disabled
                        value="urlParameter">
                      {{ $t('modals.createContext.source.urlParams') }}
                    </b-form-radio>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
      <template #modal-footer>
        <b-button
            class="p-3 border-right-light-grey"
            block
            @click="$emit('cancel-modal')"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
            variant="primary"
            class="p-3"
            block
            :disabled="disableCreateButton"
            @click="createEntry"
        >
          {{ $t('buttons.create') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalCreateNewContext',
  props: {
    personalizationSetData: {
      type: Array,
    },
    personalizationData: {
      type: Array,
    },
    availableActions: {
      type: Array,
    },
  },
  data: () => ({
    action: 'hidePage',
    source: 'personalization',
  }),
  mounted() {
    this.$refs['modal-create-context'].show();
  },
  computed: {
    disableCreateButton() {
      return !this.action || !this.source;
    },
  },
  methods: {
    createEntry() {
      this.$emit('create-context', {
        action: this.action,
        source: this.source,
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .modal-dialog {
  .modal-content {
    border: none;
    label {
      color: #6c757d;
    }
  }

  .custom-control-input ~ .custom-control-label {
    color: #6c757d;
  }
  .custom-control-input[disabled] ~ .custom-control-label {
    cursor: not-allowed;
    color: #bbb;
  }
  button:disabled {
    cursor: not-allowed;
  }
}
</style>
